let LegalLinksData = [{
    "title": "Copyright",
    "route": "copyright"
}, {
    "title": "Disclaimer",
    "route": "disclaimer"
}, {
    "title": "Terms & Conditions",
    "route": "terms"
}, {
    "title": "Privacy Policy",
    "route": "privacy"
}, {
    "title": "Security Policy",
    "route": "security"
}, {
    "title": "Cookie Policy",
    "route": "cookie"
}]

export default LegalLinksData