let AppServicesData = [{
    "File": "Header-Online-Events",
    "Title": "Engage, Compete, Triumph",
    "Description": "Offers dynamic online events including quizzes, challenges, and competitions. Earn rewards as you participate, enhance your skills, and connect with a vibrant community. Join now to compete, learn, and triumph in a wide range of engaging and interactive events."
}, {
    "File": "Header-Offline-Events",
    "Title": "Meet, Compete, Connect",
    "Description": "Brings you offline events like meetups and live quiz competitions between registered teams. Join us to network, challenge your knowledge, and build lasting connections in a fun and competitive environment. Engage in dynamic experiences that blend learning and excitement."
}, {
    "File": "Header-Challenges",
    "Title": "Beat the Clock, Master the Task",
    "Description": "Offers thrilling time-limited and short-term tasks designed to test your skills and push your limits. Compete against the clock, tackle diverse challenges, and earn rewards for your achievements. Join us to enhance your abilities and enjoy the excitement of quick, engaging tasks."
}, {
    "File": "Header-Exams",
    "Title": "Test, Achieve, Succeed",
    "Description": "Provides a seamless platform for online exams. Experience convenient and secure testing from anywhere. Assess your knowledge, track your progress, and achieve your academic and professional goals with confidence. Join us to take control of your exam journey and succeed."
}, {
    "File": "Header-Problem-Solving",
    "Title": "Conquer Challenges, Craft Solutions",
    "Description": "Offers a platform for tackling complex challenges and finding solutions to specific problems. Engage with stimulating tasks, enhance your problem-solving skills, and earn rewards for your ingenuity. Join us to turn obstacles into opportunities and showcase your expertise."
}, {
    "File": "Header-Your-Tasks",
    "Title": "Learn, Complete, Track",
    "Description": "Offers thrilling learning tasks to complete and track. Engage with exciting challenges, enhance your skills, and monitor your progress seamlessly. Join us to stay motivated, achieve your learning goals, and enjoy a rewarding experience every step of the way."
}, {
    "File": "Header-Sessions",
    "Title": "Explore Topics, Expand Knowledge",
    "Description": "Offers interactive learning sessions based on the topics listed in our website's categories. Join expert-led classes, and deepen your understanding of various subjects. Explore diverse topics and expand your knowledge with this, your gateway to focused and effective learning."
}, {
    "File": "Header-Courses",
    "Title": "Discover, Learn, Master",
    "Description": "Offers comprehensive courses based on the topics listed in our website's categories. Join expert-led classes, engage in interactive content, and deepen your understanding across various subjects. Explore and master new skills with us, your ultimate destination for focused and effective learning."
}, {
    "File": "Header-Training",
    "Title": "Train, Master, Excel",
    "Description": "Offers specialized training to master various topics listed on our website. Join expert-led training, engage in hands-on learning, and develop in-depth expertise. Elevate your skills and achieve excellence with us, your pathway to mastering new subjects and advancing your career."
}]

export default AppServicesData  