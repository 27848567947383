import React, { Component } from 'react';

class EventsPageComponent extends Component {
    render() {
        return (
            <div>
                <p>Events &nbsp; : &nbsp; Rendered Events Component</p>
            </div>
        )
    }
}
export default EventsPageComponent