import React, { Component } from 'react';
import RewardData from '../../../../Model/Wallet/WalletData';
import GetImageURL from '../../../../Utils/GetImageURL';

const onLoad = () => {
    window.scrollTo(0, 0);
};

class HelpPageComponent extends Component {
    render() {
        return (
            // TODO : Map "HelpData" model
            <div className="mx-auto p-2 w-100" onLoad={onLoad}>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h3 className="card-title">Learn & Earn</h3>
                        <p className="card-text">The innovative interactive learning platform designed to empower users through engaging educational experiences and rewarding achievements.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h3 className="card-title">About Us</h3>
                        <p className="card-text">We revolutionize learning by combining education with interactive elements and gamified rewards. Our platform allows users to buy tokens with cash, which can be used to register for a wide variety of events, challenges, sessions and courses.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h3 className="card-title">How It Works</h3>
                        <div className="card-text">
                            <ul className="list-group">
                                <li className="list-group-item"><strong>Identify Our Icons :</strong>
                                    {
                                        RewardData.map((item, index) => {
                                            return (
                                                <span key={index} className="d-flex align-items-center">
                                                    <img src={GetImageURL(item.Name)} alt={item.Name} width="30" height="30" className="xc-header-reward-icon" />
                                                    <b className="xc-header-reward-value">{item.Name}</b>
                                                </span>
                                            );
                                        })
                                    }
                                </li>
                                <li className="list-group-item"><strong>Buy Tokens :</strong> Purchase tokens with cash to gain access to our comprehensive range of events, challenges, sessions and courses.</li>
                                <li className="list-group-item"><strong>Register and Participate :</strong> Use your tokens to register for exciting events, educational challenges, insightful topic sessions and courses.</li>
                                <li className="list-group-item"><strong>Earn Rewards :</strong> Successfully completing these activities earns you reward coins and points.
                                    <ul>
                                        <li><strong>Reward Coins :</strong> Earn based on the reward value of each activities.</li>
                                        <li><strong>Reward Points :</strong> Reflect the marks or points scored in each platform activities.</li>
                                    </ul>
                                </li>
                                <li className="list-group-item"><strong>Redeem and Convert :</strong>
                                    <ul>
                                        <li><strong>Redeem Cash :</strong> Use your achieved coins to redeem cash, up to a certain limit, by raising a request through our platform.</li>
                                        <li><strong>Buy Coins with Points :</strong> Convert your earned points into coins to further engage with our activities and continue learning.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h3 className="card-title">Why Choose Us?</h3>
                        <div className="card-text">
                            <ul className="list-group">
                                <li className="list-group-item"><strong>Engaging Learning Experiences:</strong> Participate in diverse events and courses tailored to your interests and educational needs.</li>
                                <li className="list-group-item"><strong>Rewarding Achievements:</strong> Earn tangible rewards that recognize your efforts and accomplishments.</li>
                                <li className="list-group-item"><strong>Innovative Gamification:</strong> Enhance your learning journey with our unique gamified system that makes education both fun and rewarding.</li>
                                <li className="list-group-item"><strong>Flexible Rewards System:</strong> Redeem cash or convert points to coins, giving you full control over how you use your rewards.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <div className="card-text"><strong>Join us and transform the way you learn. Experience the future of education where every effort is rewarded, and every achievement is celebrated. Start your journey with us and unlock a world of knowledge and rewards!</strong></div>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}
export default HelpPageComponent