let WalletData = [{
    "Name": "Token",
    "Value": "100000",
}, {
    "Name": "Coin",
    "Value": "100000",
}, {
    "Name": "Point",
    "Value": "100000",
}]

export default WalletData