import React, { Component } from 'react';

const onLoad = () => {
    window.scrollTo(0, 0);
};

class TermsPageComponent extends Component {
    render() {
        return (
            <div className="mx-auto p-2 w-100" onLoad={onLoad}>
                <br/>
                <h4>Terms & Conditions</h4>
                <br/>
                <p>Copyright © XCOLON (OPC) PRIVATE LIMITED. All Rights Reserved.</p>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <p className="card-text"><b>Welcome to XCOLEARN!</b> These Terms and Conditions outline the rules and regulations for the use of XCOLEARN's Website, owned by XCOLON (OPC) PRIVATE LIMITED. By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use XCOLEARN's website if you do not accept all of the terms and conditions stated on this page.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Use of the Site</h6>
                        <ul>
                            <li><strong>Eligibility  :</strong> By using the Site, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into these Terms.</li>
                            <li><strong>License to Use the Site :</strong> We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use the Site for your personal, non-commercial use.</li>
                            <li><strong>Prohibited Uses :</strong> You agree not to use the Site for any unlawful purpose or any purpose prohibited under this clause. You agree not to use the Site in any way that could damage the Site, the services, or the general business of the Company.</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Intellectual Property</h6>
                        <ul>
                            <li><strong>Ownership  :</strong> All content on the Site, including text, graphics, logos, images, audio clips, video clips, software, and other materials, is the property of XCOLON (OPC) PRIVATE LIMITED and is protected by international copyright laws.</li>
                            <li><strong>Trademarks :</strong> All trademarks, service marks, and trade names used on the Site are proprietary to XCOLON (OPC) PRIVATE LIMITED unless stated otherwise. Unauthorized use of any trademark, service mark, or logo may be a violation of trademark laws.</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">User Content</h6>
                        <ul>
                            <li><strong>User-Generated Content :</strong> You may be able to submit or post content (such as comments, reviews, etc.) on the Site. You retain ownership of any intellectual property rights that you hold in that content.</li>
                            <li><strong>License to Use Content :</strong> By submitting or posting content on the Site, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, publish, and distribute such content for any purpose.</li>
                            <li><strong>Prohibited Content :</strong> You agree not to submit or post any content that is illegal, offensive, defamatory, or infringes on the rights of any third party.</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Disclaimers</h6>
                        <ul>
                            <li><strong>No Warranties :</strong> The Site is provided on an "as is" and "as available" basis. We make no warranties, expressed or implied, regarding the Site, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</li>
                            <li><strong>Limitation of Liability :</strong> In no event shall XCOLON (OPC) PRIVATE LIMITED be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the Site.</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Indemnification</h6>
                        <p className="card-text">You agree to indemnify and hold XCOLON (OPC) PRIVATE LIMITED harmless from any claims, losses, damages, liabilities, including legal fees, arising out of your use or misuse of the Site, your violation of these Terms, or your violation of any rights of a third party.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Changes to These Terms</h6>
                        <p className="card-text">We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on this page. You are advised to review these Terms periodically for any changes. Changes to these Terms are effective when they are posted on this page.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Governing Law</h6>
                        <p className="card-text">These Terms shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Contact us</h6>
                        <p className="card-text">If you have any questions about these Terms, please contact us:</p>
                        <p className="card-text">XCOLON (OPC) PRIVATE LIMITED</p>
                        <p className="card-text">Email: info@xcolon.org</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <p className="card-text"><strong>By using our Site, you hereby consent to our Terms and Conditions and agree to its terms. If you do not agree to these Terms, you should not use our Site.</strong></p>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}
export default TermsPageComponent