import React, { Component } from 'react';
import GetImageURL from '../../../../Utils/GetImageURL';

const onLoad = () => {
    window.scrollTo(0, 0);
};

class ContactPageComponent extends Component {
    render() {
        return (
            <div onLoad={onLoad}>
                <div className="container text-center">
                    <div className="row align-items-center">
                        <div className="col">
                            <div className="card xc-category-card">
                                <div className="card-body text-center">
                                    <img className="img-fluid text-center" width="500px" height="500px" src={GetImageURL("Contact")} alt="Categories" />
                                    <div>© ​🇽​​🇨​​🇴​​🇱​​🇴​​🇳​ (​🇴​​🇵​​🇨​) ​🇵​​🇷​​🇮​​🇻​​🇦​​🇹​​🇪​ ​🇱​​🇮​​🇲​​🇮​​🇮​​🇹​​🇪​​🇩​</div>
                                    <div>​🇦​​🇱​​🇱​ ​🇷​​🇮​​🇬​​🇭​​🇹​​🇸​ ​🇷​​🇪​​🇸​​🇪​​🇷​​🇻​​🇪​​🇩​</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactPageComponent