import React, { Component } from 'react';

const onLoad = () => {
    window.scrollTo(0, 0);
};

class SecurityPageComponent extends Component {
    render() {
        return (
            <div className="mx-auto p-2 w-100" onLoad={onLoad}>
                <br/>
                <h4>Security Policy</h4>
                <br/>
                <p>Copyright © XCOLON (OPC) PRIVATE LIMITED. All Rights Reserved.</p>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <p className="card-text"><b>Welcome to XCOLEARN!</b> This Security Policy details the security practices and measures that XCOLON (OPC) PRIVATE LIMITED implements to protect both the website and its users. As an interactive learning platform offering a variety of courses and quizzes, we prioritize the security of our users' data and our digital resources.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Data Protection and Privacy</h6>
                        <ul>
                            <li><strong>Encryption :</strong> All sensitive data transmitted between your device and our servers is protected using strong encryption protocols such as TLS (Transport Layer Security).</li>
                            <li><strong>Data Storage :</strong> Personal data is stored in secure databases that are protected with multiple layers of security, including firewall protection and access controls.</li>
                            <li><strong>Data Usage :</strong> We limit the use and access to user data to purposes explicitly agreed upon by users (e.g., account management, course participation, rewards redemption).</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">User Access Management</h6>
                        <ul>
                            <li><strong>Account Security :</strong> Users are required to create a strong password for their accounts, and multi-factor authentication (MFA) is encouraged to enhance account security.</li>
                            <li><strong>Session Management :</strong> User sessions are managed securely with automatic timeouts and session expirations to prevent unauthorized access.</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Payment Security</h6>
                        <ul>
                            <li><strong>Payment Processing :</strong> All online cash transactions are processed through secure, PCI DSS-compliant payment gateways.</li>
                            <li><strong>Anti-Fraud Measures :</strong> We employ advanced fraud detection and prevention technologies to monitor and protect against fraudulent transactions.</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Network and System Security</h6>
                        <ul>
                            <li><strong>Network Monitoring :</strong> Our network infrastructure is monitored continuously to detect and respond to potential security threats.</li>
                            <li><strong>Regular Audits :</strong> We conduct regular security audits and penetration testing to identify and address potential security vulnerabilities.</li>
                            <li><strong>Patch Management :</strong> We maintain a rigorous patch management policy to ensure that all systems are up-to-date with the latest security patches.</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Incident Response</h6>
                        <ul>
                            <li><strong>Incident Management :</strong> We have a formal incident response plan in place to address security breaches or data leakage incidents. This plan includes immediate measures to contain incidents, investigation procedures, and protocols to notify affected users and regulatory authorities if necessary.</li>
                            <li><strong>Continuous Improvement :</strong> Post-incident analyses are conducted to enhance future security measures and response strategies.</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Third-Party Services</h6>
                        <ul>
                            <li><strong>Vendor Selection :</strong> All third-party service providers and vendors are carefully selected based on their compliance with relevant security standards and practices.</li>
                            <li><strong>Data Sharing :</strong> Any data sharing with third parties is governed by strict contracts that ensure the confidentiality and integrity of the data.</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">User Education and Awareness</h6>
                        <ul>
                            <li><strong>Security Training :</strong> We provide regular security awareness training to our employees to ensure they are aware of security best practices and potential phishing threats.</li>
                            <li><strong>User Guidelines :</strong> Security tips and guidelines are made available to users to help them secure their accounts and personal information.</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Legal Compliance</h6>
                        <ul>
                            <li><strong>Regulatory Adherence :</strong> We adhere to all applicable laws and regulations regarding data protection, privacy, and security.</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Changes to the Security Policy</h6>
                        <ul>
                            <li><strong>Policy Updates :</strong> This Security Policy may be updated periodically to reflect new security practices and technologies. Any changes will be communicated through our website.</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Contact us</h6>
                        <p className="card-text">If you have any questions about these Policies, please contact us:</p>
                        <p className="card-text">XCOLON (OPC) PRIVATE LIMITED</p>
                        <p className="card-text">Email: info@xcolon.org</p>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}
export default SecurityPageComponent