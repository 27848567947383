import React, { Component } from 'react';

const onLoad = () => {
    window.scrollTo(0, 0);
};

class DisclaimerPageComponent extends Component {
    render() {
        return (
            <div className="mx-auto p-2 w-100" onLoad={onLoad}>
                <br/>
                <h4>Disclaimer</h4>
                <br/>
                <p>Copyright © XCOLON (OPC) PRIVATE LIMITED. All Rights Reserved.</p>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <p className="card-text"><b>Welcome to XCOLEARN!</b> This disclaimer outline the statement for the use of XCOLEARN's Website, owned by XCOLON (OPC) PRIVATE LIMITED. By accessing this website, we assume you accept these statements in full. Do not continue to use XCOLEARN's website if you do not accept all of the statements stated on this page.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">General Information</h6>
                        <p className="card-text">The information provided by XCOLON (OPC) PRIVATE LIMITED ("we," "us," or "our") on our website (the "Site") is for general informational purposes only. All information on the Site is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Professional Advice</h6>
                        <p className="card-text">The content on our Site is not intended to be a substitute for professional advice. Always seek the advice of a qualified professional with any questions you may have regarding any information presented on our Site. Never disregard professional advice or delay in seeking it because of something you have read on our Site.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">External Links Disclaimer</h6>
                        <p className="card-text">The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the Site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Technology and Software Disclaimer</h6>
                        <p className="card-text">XCOLON (OPC) PRIVATE LIMITED uses cutting-edge technology and expertise to develop software and provide technological solutions. However, the nature of software and technology is such that it may sometimes contain bugs, errors, or other issues. We do not guarantee that our software or technological solutions will be error-free or uninterrupted. Users are responsible for ensuring the suitability and compatibility of our software and technology for their specific needs and purposes.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Limitation of Liability</h6>
                        <p className="card-text">In no event shall we be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising out of or in connection with your use of the Site or any services provided by us. This includes, but is not limited to, any loss of profit, loss of data, or loss of goodwill.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Changes to the Disclaimer</h6>
                        <p className="card-text">We may update our Disclaimer from time to time. We will notify you of any changes by posting the new Disclaimer on this page. You are advised to review this Disclaimer periodically for any changes. Changes to this Disclaimer are effective when they are posted on this page.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Contact Us</h6>
                        <p className="card-text">If you have any questions about this Disclaimer, please contact us:</p>
                        <p className="card-text">XCOLON (OPC) PRIVATE LIMITED</p>
                        <p className="card-text">Email: info@xcolon.org</p>
                    </div>
                </div>
                <br/>
                <p className="card-text"><strong>By using our Site, you hereby consent to our Disclaimer and agree to its terms. If you do not agree to this Disclaimer, you should not use our Site.</strong></p>
                <br/>
            </div>
        )
    }
}
export default DisclaimerPageComponent