import AuthUserData from '../Auth/AuthUser';

let RoutesData = [{
    "title": "Home",
    "route": "",
    "isActive": "True"
}, {
    "title": "Disconnect",
    "route": "disconnect",
    "isActive": AuthUserData.isAuthenticated
}, {
    "title": "Events",
    "route": "event",
    "isActive": AuthUserData.isAuthenticated
}, {
    "title": "Academic",
    "route": "academic",
    "isActive": AuthUserData.isAuthenticated
}, {
    "title": "Challenge",
    "route": "challenge",
    "isActive": AuthUserData.isAuthenticated
}, {
    "title": "Wallet",
    "route": "wallet",
    "isActive": AuthUserData.isAuthenticated
}, {
    "title": "Account",
    "route": "account",
    "isActive": AuthUserData.isAuthenticated
}, {
    "title": "Connect",
    "route": "connect",
    "isActive": AuthUserData.isAuthenticated === "True" ? "False" : "True"
}, {
    "title": "Registration",
    "route": "registration",
    "isActive": AuthUserData.isAuthenticated === "True" ? "False" : "True"
}, {
    "title": "Forget Password",
    "route": "forgetpassword",
    "isActive": AuthUserData.isAuthenticated === "True" ? "False" : "True"
}, {
    "title": "Help",
    "route": "help",
    "isActive": "True"
}, {
    "title": "Contact",
    "route": "contact",
    "isActive": "True"
}, {
    "title": "Copyright",
    "route": "copyright",
    "isActive": "True"
}, {
    "title": "Disclaimer",
    "route": "disclaimer",
    "isActive": "True"
}, {
    "title": "Terms & Conditions",
    "route": "terms",
    "isActive": "True"
}, {
    "title": "Privacy Policy",
    "route": "privacy",
    "isActive": "True"
}, {
    "title": "Security Policy",
    "route": "security",
    "isActive": "True"
}, {
    "title": "Cookie Policy",
    "route": "cookie",
    "isActive": "True"
}, {
    "title": "PageNotFound",
    "route": "*",
    "isActive": "True"
}]

export default RoutesData