import React, { Component } from 'react';
// import GetImageURL from '../../../../Utils/GetImageURL';

class ConnectPageComponent extends Component {
    render() {
        return (
            <div className="position-absolute top-50 start-50 translate-middle text-center">
                <h1>Launch Soon</h1>
                <h3>You can connect with us after the app launch</h3>
                <br/>
                <h5>For latest updates and news, follow us on our community</h5>
            </div>
            // <div>
            //     <div className="container text-center">
            //         <div className="row align-items-center">
            //             <div className="col">
            //                 <img className="img-fluid" width="300px" height="300px" src={GetImageURL("Connect")} alt="Categories" />
            //             </div>
            //         </div>
            //         <div className="row align-items-center">
            //             <div className="col">
            //                 <form>
            //                     <br/>
            //                     <div className="input-group mx-auto p-2 w-75">
            //                         <span className="input-group-text" id="basic-addon1"><i className="fa fa-user"></i></span>
            //                         <input type="text" className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
            //                     </div>
            //                     <div className="input-group mx-auto p-2 w-75">
            //                         <span className="input-group-text" id="basic-addon2"><i className="fa fa-key"></i></span>
            //                         <input type="text" className="form-control" placeholder="Password" aria-label="Password" aria-describedby="basic-addon2" />
            //                     </div>
            //                     <br/>
            //                     <button className="btn btn-primary w-50 py-2" type="submit">Connect</button>
            //                     <br/>
            //                     <div className="text-center mx-auto p-2 w-75">
            //                         <span className="d-inline p-2"><a href="registration" className="link-secondary text-decoration-none fw-normal text-muted">SIgn Up</a></span>
            //                         <span className="d-inline p-2"><a href="forgetpassword" className="link-secondary text-decoration-none fw-normal text-muted">Reset</a></span>
            //                     </div>
            //                 </form>
            //             </div>
            //         </div>
            //     </div>
            // </div>
        )
    }
}

export default ConnectPageComponent