let SocialMediaLinksData = [{
    "title": "Facebook",
    "url": "https://www.facebook.com/xcolearn",
    "classname": "fa fa-facebook-f xc-footer-social-link"
}, {
    "title": "Instagram",
    "url": "https://www.instagram.com/xcolearn",
    "classname": "fa fa-instagram xc-footer-social-link"
}, {
    "title": "YouTube",
    "url": "https://www.youtube.com/@xcolearn-tm",
    "classname": "fa fa-youtube-play xc-footer-social-link"
}, {
    "title": "LinkedIn",
    "url": "https://www.linkedin.com/in/xcolearn-%E2%84%A2-6092522a8",
    "classname": "fa fa-linkedin xc-footer-social-link"
}, {
    "title": "X",
    "url": "https://www.x.com/xcolearn",
    "classname": "fa fa-twitter xc-footer-social-link"
}, {
    "title": "Gmail",
    "url": "mailto:info@xcolon.org",
    "classname": "fa fa-google xc-footer-social-link"
}, {
    "title": "GitHub",
    "url": "https://github.com/xcolearn",
    "classname": "fa fa-github xc-footer-social-link"
}, {
    "title": "Telegram",
    "url": "https://t.me/xcolearn",
    "classname": "fa fa-telegram xc-footer-social-link"
}, {
    "title": "WhatsApp",
    "url": "https://www.whatsapp.com/channel/0029VajxvAY3bbUvC2rDUk3d",
    "classname": "fa fa-whatsapp xc-footer-social-link"
}]

export default SocialMediaLinksData