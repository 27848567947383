import React, { Component } from 'react';

const onLoad = () => {
    window.scrollTo(0, 0);
};

class PrivacyPageComponent extends Component {
    render() {
        return (
            <div className="mx-auto p-2 w-100" onLoad={onLoad}>
                <br/>
                <h4>Privacy Policy</h4>
                <br/>
                <p>Copyright © XCOLON (OPC) PRIVATE LIMITED. All Rights Reserved.</p>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <p className="card-text"><b>Welcome to XCOLEARN!</b> an interactive learning platform operated by XCOLON (OPC) PRIVATE LIMITED ("us", "we", or "our"). Our privacy policy explains how we collect, use, and share information about you when you access or use our services through our website located at www.xcolearn.com.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Information We Collect</h6>
                        <ul>
                            <li><strong>Personal Information  :</strong> Includes your name, email address, password, and payment details that you provide when you register an account or purchase courses and programs.</li>
                            <li><strong>Usage Information :</strong> Information on how you interact with our website, such as the courses you view, quiz participation, and performance.</li>
                            <li><strong>Transactional Information :</strong> Records of the purchases you make and the rewards (coins and points) you earn and redeem.</li>
                            <li><strong>Technical Data :</strong> Includes your IP address, browser type, operating system, and other technology on the devices you use to access our website.</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">How We Use Your Information</h6>
                        <ul>
                            <li><strong>To Provide Services  :</strong> To administer and manage your account, provide courses, sessions, challenges, tasks and events, and process transactions.</li>
                            <li><strong>To Improve Services :</strong> To analyze user behavior and preferences to improve our offerings and user experience.</li>
                            <li><strong>Communication :</strong> To send you information about your account, security updates, and product information. With your consent, we may also send promotional offers and news about new events, challenges, tasks, sessions and courses.</li>
                            <li><strong>Legal Compliance :</strong> To comply with applicable laws and legal obligations.</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">How We Share Your Information</h6>
                        <ul>
                            <li><strong>Service Providers :</strong> We may share your information with third-party service providers who perform services on our behalf, including payment processing, data analysis, email delivery, hosting services, and customer service.</li>
                            <li><strong>Legal Requirements :</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">How We Protect Your Information</h6>
                        <ul>
                            <li>We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information.</li>
                            <li>Despite these measures, the security of information transmitted over the internet can never be guaranteed.</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Your Rights and Choices</h6>
                        <ul>
                            <li><strong>Account Information :</strong> You may update, correct, or delete information about you at any time by logging into your account and modifying your information.</li>
                            <li><strong>Cookies :</strong> You have the choice to accept or refuse cookies. However, if you choose to refuse cookies, some parts of our service may not function properly.</li>
                            <li><strong>Communication Preferences :</strong> You can opt-out of receiving promotional communications from us by following the unsubscribe link in those communications.</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Third-Party Links</h6>
                        <p className="card-text">Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of third-party websites.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Children’s Privacy</h6>
                        <p className="card-text">Our services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under 13, we will take steps to delete such information from our files as soon as possible.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Changes to This Privacy Policy</h6>
                        <p className="card-text">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Contact us</h6>
                        <p className="card-text">If you have any questions about these Policies, please contact us:</p>
                        <p className="card-text">XCOLON (OPC) PRIVATE LIMITED</p>
                        <p className="card-text">Email: info@xcolon.org</p>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}
export default PrivacyPageComponent