import React, { Component } from 'react';

const onLoad = () => {
    window.scrollTo(0, 0);
};

class CookiePageComponent extends Component {
    render() {
        return (
            <div className="mx-auto p-2 w-100" onLoad={onLoad}>
                <br/>
                <h4>Cookie Policy</h4>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <p className="card-text"><b>Welcome to XCOLEARN!</b> This Cookie Policy explains how XCOLEARN, owned and operated by XCOLON (OPC) PRIVATE LIMITED, uses cookies and similar technologies to recognize you when you visit our website at www.xcolearn.com. It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">What are cookies?</h6>
                        <p className="card-text">Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information. Cookies set by the website owner (in this case, XCOLON (OPC) PRIVATE LIMITED) are called "first party cookies". Cookies set by parties other than the website owner are called "third party cookies". Third party cookies enable third party features or functionality to be provided on or through the website (e.g., like advertising, interactive content, and analytics). The parties that set these third party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Why do we use cookies?</h6>
                        <p className="card-text">We use first-party and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our platform. Third parties serve cookies through our website for advertising, analytics, and other purposes. This is described in more detail below.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Types of cookies used on our website</h6>
                        <ul>
                            <li><strong>Essential cookies :</strong> These cookies are strictly necessary to provide you with services available through our website and to use some of its features, such as access to secure areas.</li>
                            <li><strong>Performance and functionality cookies :</strong> These cookies are used to enhance the performance and functionality of our website but are non-essential to their use. However, without these cookies, certain functionality (like videos) may become unavailable.</li>
                            <li><strong>Analytics and customization cookies :</strong> These cookies collect information that is used either in aggregate form to help us understand how our website is being used or how effective our marketing campaigns are, or to help us customize our website for you.</li>
                            <li><strong>Advertising cookies : </strong> These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that are based on your interests.</li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">How can we control cookies?</h6>
                        <p className="card-text">You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services. The Cookie Consent Tool can be found at the footer of the website. Additionally, most browsers allow you to control cookies through their settings preferences. However, if you limit the ability of websites to set cookies, you may worsen your overall user experience, since it will no longer be personalized to you.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Changes to the Cookie Policy</h6>
                        <p className="card-text">We may update this Cookie Policy to reflect changes to the cookies we use or for other operational, legal, or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</p>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Contact us</h6>
                        <p className="card-text">If you have any questions about our use of cookies, please contact us:</p>
                        <p className="card-text">XCOLON (OPC) PRIVATE LIMITED</p>
                        <p className="card-text">Email: info@xcolon.org</p>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}
export default CookiePageComponent