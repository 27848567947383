import React, { Component } from 'react';
import AuthUserData from '../../../Model/Auth/AuthUser'
import ConnectPageComponent from '../Pages/Auth/Connect'
import DisconnectPageComponent from '../Pages/Auth/Disconnect'
import RegistrationPageComponent from '../Pages/Auth/Registration'
import ForgetPasswordPageComponent from '../Pages/Auth/ForgetPassword'
import HomePageComponent from '../Pages/Home'
import AccountPageComponent from '../Pages/Auth/Account'
import AcademicPageComponent from '../Pages/App/Academic'
import EventsPageComponent from '../Pages/App/Events'
import WalletPageComponent from '../Pages/App/Wallet'
import ChallengesPageComponent from '../Pages/App/Challenges'
import ContactPageComponent from '../Pages/Support/Contact'
import HelpPageComponent from '../Pages/Support/Help'
import CookiePageComponent from '../Pages/Legal/Cookie'
import DisclaimerPageComponent from '../Pages/Legal/Disclaimer'
import CopyrightPageComponent from '../Pages/Legal/Copyright'
import PrivacyPageComponent from '../Pages/Legal/Privacy'
import SecurityPageComponent from '../Pages/Legal/Security'
import TermsPageComponent from '../Pages/Legal/Terms'
import PageNotFoundPageComponent from '../../../Shared/Error/PageNotFound'

class RoutesComponent extends Component {
    render() {
        const { page } = this.props
        return (
            <div>
                { AuthUserData.isAuthenticated === "True" && page === "disconnect" ? <DisconnectPageComponent /> : <></> }
                { AuthUserData.isAuthenticated === "True" && page === "account" ? <AccountPageComponent /> : <></> }
                { AuthUserData.isAuthenticated === "True" && page === "academic" ? <AcademicPageComponent /> : <></> }
                { AuthUserData.isAuthenticated === "True" && page === "event" ? <EventsPageComponent /> : <></> }
                { AuthUserData.isAuthenticated === "True" && page === "challenge" ? <ChallengesPageComponent /> : <></> }
                { AuthUserData.isAuthenticated === "True" && page === "wallet" ? <WalletPageComponent /> : <></> }
                { AuthUserData.isAuthenticated !== "True" && page === "connect" ? <ConnectPageComponent /> : <></> }
                { AuthUserData.isAuthenticated !== "True" && page === "registration" ? <RegistrationPageComponent /> : <></> }
                { AuthUserData.isAuthenticated !== "True" && page === "forgetpassword" ? <ForgetPasswordPageComponent /> : <></> }
                { page === "" ? <HomePageComponent /> : <></> }
                { page === "contact" ? <ContactPageComponent /> : <></> }
                { page === "help" ? <HelpPageComponent /> : <></> }
                { page === "cookie" ? <CookiePageComponent /> : <></> }
                { page === "disclaimer" ? <DisclaimerPageComponent /> : <></> }
                { page === "copyright" ? <CopyrightPageComponent /> : <></> }
                { page === "privacy" ? <PrivacyPageComponent /> : <></> }
                { page === "security" ? <SecurityPageComponent /> : <></> }
                { page === "terms" ? <TermsPageComponent /> : <></> }
                { page === "*" ? <PageNotFoundPageComponent /> : <></> }
            </div>
        )
    }
}
export default RoutesComponent