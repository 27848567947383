let HeaderLinksData = [{
    "title": "Events",
    "route": "event"
}, {
    "title": "Academic",
    "route": "academic"
}, {
    "title": "Wallet",
    "route": "wallet"
}, {
    "title": "Account",
    "route": "account"
}]

export default HeaderLinksData