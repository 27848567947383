import React, { Component } from 'react';

const onLoad = () => {
    window.scrollTo(0, 0);
};

class CopyrightPageComponent extends Component {
    render() {
        return (
            <div className="mx-auto p-2 w-100" onLoad={onLoad}>
                <br/>
                <h4>Copyright Ownership</h4>
                <br/>
                <div className="card">
                    <div className="card-body xc-card-content">
                        <h6 className="card-title">Copyright © XCOLON (OPC) PRIVATE LIMITED. All Rights Reserved.</h6>
                        <p className="card-text">This website and its content, including but not limited to text, graphics, logos, images, and software, are the property of XCOLON (OPC) PRIVATE LIMITED and are protected by international copyright laws. Unauthorized use, reproduction, or distribution of any part of this website is strictly prohibited and may result in legal action. For permissions or inquiries regarding the use of our content, please contact us directly. Your use of this website signifies your agreement to respect and adhere to these copyright terms.</p>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}
export default CopyrightPageComponent