import React, { Component } from 'react';

class PageNotFoundPageComponent extends Component {
    render() {
        return (
            <div className="position-absolute top-50 start-50 translate-middle text-center">
                <h1>404</h1>
                <h2>Page Not Found</h2>
                <p>
                    Sorry, the page you are looking
                    for does not exist
                </p>
            </div>
        )
    }
}
export default PageNotFoundPageComponent