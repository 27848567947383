import React, { Component } from 'react';
import AuthUserData from '../../Model/Auth/AuthUser';
import Logo from '../../Assets/Images/Logo-Transparent.png';
import HeaderLinksData from '../../Model/Links/HeaderLinks';
import SupportLinksData from '../../Model/Links/SupportLinks';
import LegalLinksData from '../../Model/Links/LegalLinks';
import WalletData from '../../Model/Wallet/WalletData';
import GetImageURL from '../../Utils/GetImageURL';

class Header extends Component {
  render() {
      return (
      <nav className="navbar fixed-top navbar-expand-lg bg-body-tertiary bg-dark border-bottom border-body xc-nav-header" data-bs-theme="dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={Logo} alt="Logo" width="25" height="25" className="d-inline-block align-text-top" />
            <span className="xc-brand-header"><span className="xc-brand-header-letter">X</span>CO<span className="xc-brand-header-letter">L</span>EARN ™</span>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="offcanvas offcanvas-end text-bg-dark xc-header-canvas" tabIndex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
            <div className="offcanvas-header xc-header-title-canvas">
              <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel"><span className="xc-brand-header-letter">X</span>CO<span className="xc-brand-header-letter">L</span>EARN ™</h5>
              <a href="https://www.xcolon.in" className="xc-nav-canvas-header-link">&nbsp;&nbsp;&nbsp; © <span className="xc-brand-header-letter">X</span>COLON (OPC) PRIVATE LIMITED</a>
              <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {
                  WalletData.map((item, index) => {
                    return AuthUserData.isAuthenticated === "True" ?
                      <li key={index} className="d-flex align-items-center">
                        <img src={GetImageURL(item.Name)} alt={item.Name} width="30" height="30" className="xc-header-reward-icon" />
                        <b className="xc-header-reward-value">{item.Value}</b>
                      </li>
                    : <li key={index} className="d-flex align-items-center"></li>
                  })
                }
                {
                  HeaderLinksData.map((item, index) => {
                    return AuthUserData.isAuthenticated === "True" ?
                      <li className="nav-item" key={index}><a className="nav-link xc-header-link" href={item.route}><b>{item.title}</b></a></li>
                    : <li className="nav-item" key={index}></li>
                  })
                }
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle xc-header-menu-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <b>Support</b>
                  </a>
                  <ul className="dropdown-menu dropdown-menu-dark xc-header-menu">
                    {
                      SupportLinksData.map((item, index) => {
                        return <li key={index}><a className="dropdown-item xc-header-menu-item" href={item.route}><b>{item.title}</b></a></li>
                      })
                    }
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle xc-header-menu-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <b>Legal</b>
                  </a>
                  <ul className="dropdown-menu xc-header-menu">
                    {
                      LegalLinksData.map((item, index) => {
                        return <li key={index}><a className="dropdown-item xc-header-menu-item" href={item.route}><b>{item.title}</b></a></li>
                      })
                    }
                  </ul>
                </li>
              </ul>
              <span className="navbar-text">
                {
                  AuthUserData.isAuthenticated === "True" ?
                    <a className="nav-link" href="disconnect"><b>Disconnect</b></a>
                  : <a className="nav-link" href="connect"><b>Connect</b></a>
                }
              </span>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Header;