import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './Assets/Styles/Main.css';
import './Assets/Styles/Layout.css';
import './Assets/Styles/Header.css';
import './Assets/Styles/Footer.css';
import './Assets/Styles/UnAuthorized.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
