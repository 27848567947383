import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import RoutesData from '../../Model/Routes/RoutesData';
import RoutesComponent from '../../Contents/Components/Routes/RoutesComponent';

class ContentLayoutPageComponent extends Component {
  render() {
    return (
      <div className="xc-layout">
        <Header/>
        <div className="container-fluid xc-app-container">
          <Routes>
            {
              RoutesData.map((item, index) => {
                return item.isActive === "True" ?
                  <Route key={index} path={item.route} Component={() => <RoutesComponent page={item.route} />} />
                : <Route key={index} path="" Component={() => <RoutesComponent page="" />} />
              })
            }
          </Routes>
        </div>
        <Footer/>
      </div>
    );
  } 
}

export default ContentLayoutPageComponent;