import React, { Component } from 'react';

class ForgetPasswordPageComponent extends Component {
    render() {
        return (
            <div>
                <br/><br/>
                <div className="container text-center">
                    <div className="row align-items-center">
                        <div className="col">
                            <form>
                                <div className="input-group mx-auto p-2 w-75">
                                    <span className="input-group-text" id="basic-addon1"><i className="fa fa-user"></i></span>
                                    <input type="text" className="form-control" placeholder="Confirm Username" aria-label="Confirm Username" aria-describedby="basic-addon1" />
                                </div>
                                <div className="input-group mx-auto p-2 w-75">
                                    <span className="input-group-text" id="basic-addon2"><i className="fa fa-key"></i></span>
                                    <input type="text" className="form-control" placeholder="New Password" aria-label="New Password" aria-describedby="basic-addon2" />
                                </div>
                                <div className="input-group mx-auto p-2 w-75">
                                    <span className="input-group-text" id="basic-addon3"><i className="fa fa-unlock"></i></span>
                                    <input type="text" className="form-control" placeholder="Confirm Password" aria-label="Confirm Password" aria-describedby="basic-addon3" />
                                </div>
                                <div className="input-group mx-auto p-2 w-75">
                                    <span className="input-group-text" id="basic-addon4"><i className="fa fa-envelope"></i></span>
                                    <input type="text" className="form-control" placeholder="Confirm Email" aria-label="Confirm Email" aria-describedby="basic-addon4" />
                                </div>
                                <div className="input-group mx-auto p-2 w-75">
                                    <span className="input-group-text" id="basic-addon5"><i className="fa fa-wifi"></i></span>
                                    <input type="text" className="form-control" placeholder="Confirm Mobile" aria-label="Confirm Mobile" aria-describedby="basic-addon5" />
                                </div>
                                <div className="input-group mx-auto p-2 w-75">
                                    <span className="input-group-text" id="basic-addon6"><label htmlFor="dobDate">DOB</label></span>
                                    <input id="dobDate" type="date" className="form-control" placeholder="DOB" aria-label="DOB" aria-describedby="basic-addon6" />
                                </div>
                                <br/>
                                <button className="btn btn-primary w-50 py-2" type="submit">Reset Password</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ForgetPasswordPageComponent