import React, { Component } from 'react';

class WalletPageComponent extends Component {
    render() {
        return (
            <div>
                <p>Wallet &nbsp; : &nbsp; Rendered Wallet Component</p>
            </div>
        )
    }
}
export default WalletPageComponent